import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import CustomPaginationBox from "../../components/CustomPaginationBox";
import DotLoader from "../../components/DotLoader";

import "./paymentProcessor.css";
import { IMAGES } from "../../constants/images";
import AXIOS_REQUEST from "../../helpers/Axios";
import { getDefaultPP_API, getFormDetailsAPI } from "../../APIs";
import { useScrollTo } from "../../hooks/useScrollTo";
import { ROUTE_URLS } from "../../constants/URLS";
import {
  CREATE_FORM_DETAILS_ENDPOINT,
  PAYPAL_ACCOUNT_CHECK_ENDPOINT,
  STRIPE_ACCOUNT_CHECK_ENDPOINT,
  UPDATE_DEFAULT_PP,
} from "../../constants/API_ENDPOINTS";
import { CapitalizeAWord } from "../../helpers/helperFunctions";

export default function PaymentProcessor() {
  const { SETUP_PAYMENT, CRM } = ROUTE_URLS;
  useScrollTo(0, 0);
  const { form_id } = useParams();
  const navigate = useNavigate();

  const [cookies, setCookie] = useCookies([
    process.env.REACT_APP_CREATE_FORM_COOKIE_NAME,
  ]);
  const [Tokencookie] = useCookies([process.env.REACT_APP_TOKEN_COOKIE_NAME]);

  let token;
  const [selectedPP, setSelectedPP] = useState();
  const [loading, setLoading] = useState(false);

  const getDefaultPP = async () => {
    try {
      setLoading(true);
      let data = await getDefaultPP_API(
        "Bearer " +
          Tokencookie[process.env.REACT_APP_TOKEN_COOKIE_NAME].access_token
      );
      if (data) {
        setLoading(false);
        setSelectedPP(data.default_PP);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  //get form details step 3
  const getFormDetails = async () => {
    try {
      setLoading(true);
      let response = await getFormDetailsAPI(
        form_id,
        3,
        "Bearer " +
          Tokencookie[process.env.REACT_APP_TOKEN_COOKIE_NAME].access_token
      );
      if (response) {
        setLoading(false);
        if (response.data[0]?.payment_processor) {
          setLoading(false);
          setSelectedPP(response.data[0]?.payment_processor);
        } else {
          setLoading(false);
          getDefaultPP();
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  //get form details(if there) on component load
  useEffect(() => {
    if (form_id) {
      getFormDetails();
    } else {
      getDefaultPP();
    }
  }, []);

  // to change the Payment Processor
  const handleUpdatePP = async (PP) => {
    // if (PP !== selectedPP) {
    //   setLoading(true);
    //   const data = {
    //     to: PP,
    //   };
    //   let response = await AXIOS_REQUEST.post(UPDATE_DEFAULT_PP, data, {
    //     headers: {
    //       Authorization: "Bearer " + cookies[process.env.REACT_APP_TOKEN_COOKIE_NAME].access_token,
    //       "Content-Type": "application/json",
    //     },
    //   });
    //   if (response.data.status) {
    //     setLoading(false);
    //     toast.info(response.data.message, {
    //       closeButton: true,
    //     });
    //     setSelectedPP(PP);
    //   } else {
    //     setLoading(false);
    //     onCheckPPAccount(1, PP);
    //   }
    // }
    if (PP !== selectedPP) {
      onCheckPPAccount(1, PP);
    }
  };

  const CheckWindowOpenedOrNot = (newTab) => {
    token = setInterval(() => {
      if (newTab.closed) {
        clearInterval(token);
        setLoading(false);
      }
    }, 1000);
  };

  //checking Payment Processor Account is there or not if not then create one
  const onCheckPPAccount = async (check, selectedPP) => {
    // if (selectedPP == "stripe") {
    try {
      setLoading(true);
      let url;
      if (selectedPP == "stripe") {
        url = STRIPE_ACCOUNT_CHECK_ENDPOINT;
      } else if (selectedPP == "paypal") {
        url = PAYPAL_ACCOUNT_CHECK_ENDPOINT;
      }
      let response = await AXIOS_REQUEST.get(url);
      if (response) {
        setLoading(false);
        if (response.data.status == false) {
          if (!response.data.url) {
            toast.error(
              "Server Error: URL not provided for " +
                CapitalizeAWord(selectedPP)
            );
          } else {
            setLoading(true);
            const newTab = window.open(response.data.url, "_blank");

            CheckWindowOpenedOrNot(newTab);

            // Add an event listener to listen for messages from the new tab
            window.addEventListener("message", function (event) {
              // Check if the message is coming from the new tab

              if (event.source === newTab) {
                event.source.postMessage(
                  "Message from original tab",
                  event.origin
                );
                console.log(event, "event");
                toast.success(
                  CapitalizeAWord(selectedPP) +
                    " has been connected successfully."
                );
                setSelectedPP(selectedPP);
              }
            });
          }
        } else {
          let payload = {
            form_step: 3,
            payment_processor: selectedPP,
            form_id: form_id
              ? form_id
              : Tokencookie[process.env.REACT_APP_CREATE_FORM_COOKIE_NAME].id,
          };
          let response = await AXIOS_REQUEST.post(
            CREATE_FORM_DETAILS_ENDPOINT,
            payload,
            {
              headers: {
                Authorization:
                  "Bearer " +
                  Tokencookie[process.env.REACT_APP_TOKEN_COOKIE_NAME]
                    .access_token,
              },
            }
          );
          if (response.data.status) {
            setSelectedPP(response.data.details.payment_processor);
            if (check !== 1) {
              if (form_id) {
                navigate(`${CRM}/${form_id}`);
              } else {
                let newObj = {
                  ...cookies[process.env.REACT_APP_CREATE_FORM_COOKIE_NAME],
                  ...response.data.details,
                };
                setCookie(
                  process.env.REACT_APP_CREATE_FORM_COOKIE_NAME,
                  JSON.stringify(newObj)
                );
                navigate(CRM);
              }
            }
          } else {
            toast.error("Something went wrong.");
          }
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong...: " + error.message);
    }
    // }
    // else {
    //   toast.info("Please Select Stripe for now.");
    // }
  };

  return (
    <>
      <DotLoader loading={loading} />
      <Header />
      <div id="payment_process">
        <div className="payment_processor Paymentedit_sec crm_sec ">
          <div className="page_container">
            <h1>Connect your Payment Processor</h1>

            <div className="crm_main">
              <div className="crm_body">
                <div className="content_box">
                  <div
                    className="content content1"
                    onClick={() => handleUpdatePP("stripe")}
                    style={{
                      border:
                        selectedPP == "stripe" ? `2px solid #e46e2e ` : null,
                    }}
                  >
                    <div className="img_py_sec">
                      <img src={IMAGES.Stripe_logo_revised_} alt="Edit CRM" />
                    </div>
                  </div>
                  {/*<div
                    className="content content2"
                    onClick={() => handleUpdatePP("paypal")}
                    style={{
                      border:
                        selectedPP == "paypal" ? `2px solid #e46e2e ` : null,
                    }}
                  >
                    <div className="img_py_sec">
                      <img src={IMAGES.paypal_logo} alt="content2img" />
                    </div>
                  </div> */}
                  {/* 
                  <div className="content content3">
                    <img src={IMAGES.gocardless} alt="content3" />
                  </div>
                  <div className="content content4">
                    <img src={IMAGES.takepayment} alt="content41" />
                    <img src={IMAGES.takepaymenttext} alt="content42" />
                  </div> */}
                </div>
              </div>
            </div>
            <div className="pagination_outer">
              <CustomPaginationBox page_number={3} total_pages={5} />
              <div className="next_buttn">
                <Link
                  to={form_id ? `${SETUP_PAYMENT}/${form_id}` : SETUP_PAYMENT}
                  className="back_btn"
                >
                  <button>Back</button>
                </Link>
                <button onClick={() => onCheckPPAccount(0, selectedPP)}>
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
